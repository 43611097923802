<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none icon-white d-lg-block " />

    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <notification-dropdown class="d-none icon-white d-lg-block" /> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav icon-white">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
            <span class="user-status"> {{ userRole }}</span>
          </div>
          <b-avatar
            v-if="avatar === null"
            size="40"
            variant="light-primary"
            :src="require('@/assets/images/icons/users.png')"
          />
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            :src="`https://api.arginipratamaputra.com${avatar}`"
          />
        </template>

        <!-- <b-dropdown-item
          :to="{ name: 'pages-account-setting' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-item
          :to="{ name: 'pages-account-setting' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
// import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // NotificationDropdown,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userName: '',
      userRole: '',
      avatar: null,
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.userName = userData.user.nama
    if (userData.user.role === '1') {
      this.userRole = 'Administrator'
    } else if (userData.user.role === '2') {
      this.userRole = 'Employee'
    } else {
      this.userRole = 'External'
    }
    // this.userRole = userData.user.role === '1' ? 'Administrator' : 'Employee'
    this.avatar = userData.user.foto !== '' ? userData.user.foto : null
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      // localStorage.removeItem('permission')

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss" >
.navbar-container {
  background: linear-gradient(to right, #00427a, #1361a3);
  border-radius: 15px;
  .user-name {
    text-transform: capitalize;
  }
  .nav-item {
    color: #fff;
  }
  .icon-white {
    a,
    div,
    p,
    span {
      color: #fff;
    }
  }
}
</style>
