// import store from '@/store/index'

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  // {
  //   title: 'User Data',
  //   route: 'users',
  //   icon: 'UserIcon',
  //   Permission: 'admin',
  // },

  // {
  //   title: 'Work Assignment',
  //   route: 'assignment',
  //   icon: 'Edit3Icon',
  //   Permission: 'admin',
  // },

  // {
  //   title: 'To Do List',
  //   route: 'todo',
  //   icon: 'CheckSquareIcon',
  //   disabled: store.state.userData.user.role === 1,
  // },

  {
    title: 'To Do List',
    route: 'todo',
    icon: 'CheckSquareIcon',
  },

  // {
  //   title: 'Report Todo',
  //   route: 'work-management',
  //   icon: 'FileTextIcon',
  // },

  // {
  //   title: 'Area ',
  //   route: 'area-list',
  //   icon: 'CompassIcon',
  //   Permission: 'admin',
  // },

  // {
  //   title: 'Work ',
  //   route: 'work',
  //   icon: 'ClipboardIcon',
  //   Permission: 'admin',
  // },

  {
    title: 'Chemical',
    route: 'chemical',
    icon: 'PackageIcon',
  },

  {
    title: 'Equipment',
    route: 'equipment',
    icon: 'ToolIcon',
  },

  // {
  //   title: 'Agenda',
  //   route: 'agenda',
  //   icon: 'CalendarIcon',
  // },

  {
    title: 'Garden',
    route: 'garden',
    icon: 'SlackIcon',
  },

  {
    title: 'Biopori',
    route: 'biopori',
    icon: 'CrosshairIcon',
  },

  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
]
