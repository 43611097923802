// import store from '@/store/index'

const lala = [{
  title: 'Home',
  route: 'home',
  icon: 'HomeIcon',
},
{
  title: 'User Data',
  route: 'users',
  icon: 'UserIcon',
  Permission: 'admin',
},

{
  title: 'Work Assignment',
  route: 'assignment',
  icon: 'Edit3Icon',
  Permission: 'admin',
},

{
  title: 'Summary',
  route: 'summary',
  icon: 'FileTextIcon',
},
// {
//   title: 'To Do List',
//   route: 'todo',
//   icon: 'CheckSquareIcon',
// },

{
  title: 'Area ',
  route: 'area-list',
  icon: 'CompassIcon',
  Permission: 'admin',
},

{
  title: 'Work ',
  route: 'work',
  icon: 'ClipboardIcon',
  Permission: 'admin',
},

{
  title: 'Chemical',
  route: 'chemical',
  icon: 'PackageIcon',
},

{
  title: 'Equipment',
  route: 'equipment',
  icon: 'ToolIcon',
},

// {
//   title: 'Agenda',
//   route: 'agenda',
//   icon: 'CalendarIcon',
// },

{
  title: 'Garden',
  route: 'garden',
  icon: 'SlackIcon',
},

{
  title: 'Biopori',
  route: 'biopori',
  icon: 'CrosshairIcon',
},
]

export default lala
